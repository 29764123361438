import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import AutoGraphOutlinedIcon from "@mui/icons-material/AutoGraphOutlined";
import ScienceOutlinedIcon from "@mui/icons-material/ScienceOutlined";
import PlayForWorkOutlinedIcon from "@mui/icons-material/PlayForWorkOutlined";
import WorkspacesOutlinedIcon from "@mui/icons-material/WorkspacesOutlined";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import AppsOutlinedIcon from "@mui/icons-material/AppsOutlined";
import ReportOutlinedIcon from "@mui/icons-material/ReportOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import FingerprintOutlinedIcon from "@mui/icons-material/FingerprintOutlined";
import GavelOutlinedIcon from "@mui/icons-material/GavelOutlined";

export const data = [
  {
    label: "Data Engineering",
    items: [
      {
        icon: <CloudUploadOutlinedIcon />,
        label: "Ingest",
        route: "ingest",
        asyncApiURI: "/specifications/asyncapi/ingest.yaml",
        openApiURI: "/specifications/openapi/ingest.yaml",
      },
    ],
  },

  {
    label: "Data Analytics",
    items: [
      {
        icon: <TableChartOutlinedIcon />,
        label: "Exploration",
        route: "exploration",
        asyncApiURI: "/specifications/asyncapi/exploration.yaml",
        openApiURI: "/specifications/openapi/exploration.yaml",
      },
      {
        icon: <AutoGraphOutlinedIcon />,
        label: "Modeling",
        route: "modeling",
        asyncApiURI: "/specifications/asyncapi/modeling.yaml",
        openApiURI: "/specifications/openapi/modeling.yaml",
      },
      {
        icon: <ScienceOutlinedIcon />,
        label: "Design Of Experiments",
        route: "design-of-experiments",
        asyncApiURI: "/specifications/asyncapi/doe.yaml",
        openApiURI: "/specifications/openapi/doe.yaml",
      },
    ],
  },

  {
    label: "Data Science",
    items: [
      {
        icon: <PlayForWorkOutlinedIcon />,
        label: "Function Registry",
        route: "funcregexecution",
        asyncApiURI: "/specifications/asyncapi/funcreg_execution.yaml",
        openApiURI: "/specifications/openapi/funcreg_execution.yaml",
      },
      {
        icon: <PlayForWorkOutlinedIcon />,
        label: "Execution",
        route: "studio_execution",
        asyncApiURI: "/specifications/asyncapi/studio_execution.yaml",
        openApiURI: "/specifications/openapi/studio_execution.yaml",
      },
      {
        icon: <WorkspacesOutlinedIcon />,
        label: "Workflows",
        route: "workflows",
        asyncApiURI: "/specifications/asyncapi/workflows.yaml",
        openApiURI: "/specifications/openapi/workflows.yaml",
      },
    ],
  },
  {
    label: "Operations",
    items: [
      {
        icon: <NotificationsActiveOutlinedIcon />,
        label: "Notifications",
        route: "notifications",
        asyncApiURI: "/specifications/asyncapi/notifications.yaml",
        openApiURI: "/specifications/openapi/notifications.yaml",
      },
    ],
  },

  {
    label: "Administration",
    items: [
      {
        icon: <AppsOutlinedIcon />,
        label: "Tenants Management",
        route: "tenants-management",
        asyncApiURI: "/specifications/asyncapi/tenants-management.yaml",
        openApiURI: "/specifications/openapi/tenants-management.yaml",
      },
      {
        icon: <ReportOutlinedIcon />,
        label: "Tenants Registration",
        route: "tenants-registration",
        asyncApiURI: "/specifications/asyncapi/tenants-registration.yaml",
        openApiURI: "/specifications/openapi/tenants-registration.yaml",
      },
      {
        icon: <ReportOutlinedIcon />,
        label: "Collaboration Areas",
        route: "collaboration-areas",
        openApiURI: "/specifications/openapi/collaboration-areas.yaml",
      },
      {
        icon: <ReportOutlinedIcon />,
        label: "Users Facade",
        route: "users-facade",
        asyncApiURI: "/specifications/asyncapi/users-facade.yaml",
        openApiURI: "/specifications/openapi/users-facade.yaml",
      },
      {
        icon: <AccountTreeOutlinedIcon />,
        label: "Project Management",
        route: "projmgmt",
        asyncApiURI: "/specifications/asyncapi/projects.yaml",
        openApiURI: "/specifications/openapi/projects.yaml",
      },
      {
        icon: <AccountTreeOutlinedIcon />,
        label: "Project Management Request Adapter",
        route: "projmgmt-request-adapter",
        asyncApiURI: "/specifications/asyncapi/projects-request-adapter.yaml",
        openApiURI: "/specifications/openapi/projects-request-adapter.yaml",
      },
    ],
  },

  {
    label: "Governance",
    items: [
      {
        icon: <VerifiedUserOutlinedIcon />,
        label: "Auditing",
        route: "auditing",
        asyncApiURI: "/specifications/asyncapi/auditing.yaml",
        openApiURI: "/specifications/openapi/auditing.yaml",
      },
      {
        icon: <FingerprintOutlinedIcon />,
        label: "Access Management request adapter",
        route: "accessmgmt-request-adapter",
        asyncApiURI: "/specifications/asyncapi/accessmgmt-request-adapter.yaml",
        openApiURI: "/specifications/openapi/accessmgmt-request-adapter.yaml",
      },
      {
        icon: <FingerprintOutlinedIcon />,
        label: "Access Management microservice",
        route: "accessmgmt-microservice",
        asyncApiURI: "/specifications/asyncapi/accessmgmt-microservice.yaml",
        openApiURI: "/specifications/openapi/accessmgmt-microservice.yaml",
      },
      {
        icon: <FingerprintOutlinedIcon />,
        label: "Access Management policies manager",
        route: "accessmgmt-policies-manager",
        asyncApiURI:
          "/specifications/asyncapi/accessmgmt-policies-manager.yaml",
        openApiURI: "/specifications/openapi/accessmgmt-policies-manager.yaml",
      },
      {
        icon: <FingerprintOutlinedIcon />,
        label: "Access Management keycloak providers",
        route: "accessmgmt-keycloak-providers",
        asyncApiURI:
          "/specifications/asyncapi/accessmgmt-keycloak-providers.yaml",
        openApiURI:
          "/specifications/openapi/accessmgmt-keycloak-providers.yaml",
      },
      {
        icon: <GavelOutlinedIcon />,
        label: "Licensing",
        route: "licensing",
        asyncApiURI: "/specifications/asyncapi/licensing.yaml",
        openApiURI: "/specifications/openapi/licensing.yaml",
      },
    ],
  },
  {
    label: "API Gate",
    items: [
      {
        icon: <VerifiedUserOutlinedIcon />,
        label: "Gate",
        route: "gate",
        openApiURI: "/specifications/openapi/gate.yaml",
      },
    ],
  },
];

export const fromRoute = (route) => {
  let item = undefined;
  data.forEach((d) => {
    d.items.forEach((i) => {
      if (i.route === route) {
        item = i;
      }
    });
  });
  return item;
};
