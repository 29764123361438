import * as React from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

export const Home = ()=>{
    return (
        <div>
        <Box >
          <Grid container spacing={0.5}>
             <Grid item xs={12}>
               <Paper>
                 <h2>Studio Development Contracts</h2>
                 <p>Select your favourite bounded context from the list.</p>
                 <h2>Information Model</h2>
                 <img src="specifications/model/information-model.svg" width="100%" alt="Information Model" />
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </div>
    );
}
