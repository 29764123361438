import * as React from 'react';

import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {Link} from 'react-router-dom';


export default function IconMenu(props) {
    return (
        <>
            {props.data.map((group) => (
                <List
                    key={group.label}
                    sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            {group.label}
                        </ListSubheader>
                    }
                >
                    <MenuGroup items={group.items} current={props.current}/>
                </List>))}
        </>
    );
}

const LinkBehavior = React.forwardRef((props, ref) => (
    <Link ref={ref} to="/" {...props} role={undefined}/>
));

function MenuGroup(props) {
    return (
        <>
            {props.items.map((item) => (
                <ListItemButton key={item.label} component={LinkBehavior} to={item.route} dense={true}
                                selected={props.current === item.route}>
                    <ListItemIcon>
                        {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.label}/>
                </ListItemButton>
            ))}
        </>
    )
}

