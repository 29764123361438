import './App.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import IconMenu from './components/menu.js'
import DocTabs from './components/tabs.js'

/* icons */
import {data} from "./data";
import {BrowserRouter as Router, Link, Route, Routes} from 'react-router-dom';
import * as React from "react";
import {Home} from "./components/home";
import HomeOutlined from "@mui/icons-material/HomeOutlined";
import styled from 'styled-components';

function App() {
  const [current, setCurrent] = React.useState("");

  const StyledLink = styled(Link)`
    text-decoration: none;
    display: flex;
    color: rgba(0, 0, 0, 0.87);

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color: inherit;
    }
  `;

    return (
    <Router>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0.5}>

          <Grid item xs={12}>
            <Paper>
              <StyledLink to="/"><HomeOutlined />Studio Development Contracts</StyledLink>
            </Paper>
          </Grid>

          <Grid item xs={2}>
            <Paper>
              <IconMenu data={data} current={current}/>
            </Paper>
          </Grid>
          <Grid item xs={10}>

              <Routes>
                <Route path="/:bc" element= {<DocTabs current={setCurrent}/>} />
                <Route path="/" element= {<Home />} />
              </Routes>

          </Grid>

        </Grid>
      </Box>
    </Router>
  );
}

export default App;
