import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import AsyncApiComponent from "@asyncapi/react-component";
import "@asyncapi/react-component/lib/styles/fiori.css";
import "./styles.css";


import {RedocStandalone} from 'redoc';
import {useNavigate, useParams} from "react-router-dom";

import {fromRoute} from "../data";
import {fetchUrl} from "./fetch";


const config = {
  show: {
    schemas: true
  },
  expand: {
    channels: {
      root: true,
      elements: true
    },
    servers: {
      root: true,
      elements: true
    },
    messages: {
      root: true,
      elements: true
    },
    schemas: {
      root: true,
      elements: true
    },
  },
  showErrors: true
};


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function DocTabs(props) {
    const [value, setValue] = React.useState(0);
    const [wfSpec, setWfSpec] = React.useState(null);
    const [specUrl, setSpecUrl] = React.useState(null);
    let navigate = useNavigate();

      const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    let { bc } = useParams();
    props.current(bc);
    React.useEffect(function effectFunction() {
        const item = fromRoute(bc);
        if(!item) {
            navigate("/");
        } else {
            console.log("DocTab", bc, item);
            fetchUrl(item.asyncApiURI).then((d)=>{
                console.log("d=",d);
                setWfSpec(d);
            });
            setSpecUrl(item.openApiURI);
        }
    }, [bc,navigate]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Message API" {...a11yProps(0)} />
          <Tab label="Rest API" {...a11yProps(1)} />
          <Tab label="Storybooks" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
          {wfSpec !== null ? <AsyncApiComponent config={config} schema={wfSpec} /> : <></>}
      </TabPanel>
      <TabPanel value={value} index={1}>
          {specUrl !== null ?
      <RedocStandalone
          specUrl={specUrl}
      options={{
          nativeScrollbars: true,
              theme: { colors: { primary: { main: '#dd5522' } } },
      }}/> : <></>}
      </TabPanel>
      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel>
    </Box>
  );
}
